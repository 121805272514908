import React from 'react';
import PureComponent from '../../pure';
import sAction from 'sAction';
import propTypes from 'prop-types';

export default class RelateField extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedCount: 0,
        };
    }

    componentDidUpdate() {
        this.getFilterSelectedCount();
    }

    componentDidMount() {
        this.getFilterSelectedCount();
    }

    /**
     *
     */
    getFilterSelectedCount() {
        const prefix = this.props.prefix;
        const fieldModule = this.props.fieldModule;
        const def = this.props.def;

        if (!def) {
            return;
        }

        const currentFilters = sAction.dataGet(prefix + '/filter');

        let fieldRel = this.props.fieldRel;
        let field = def.get('id_name');

        if (!fieldRel && def.get('link')) {
            field = 'id';
            fieldRel = [def.get('link')];
        }

        currentFilters.map((filter) => {
            if (filter.fieldRel?.toString?.() === fieldRel?.toString?.() && filter.field === field && filter.fieldModule === fieldModule) {
                this.setState({selectedCount: filter.filters.size});
            }
        });
    }

    /**
     *
     */
    openRelateListView() {
        const filterData = sAction.getStorage('fieldRelateSelected') || {};
        const fieldModule = this.props.fieldModule;
        const field = this.props.field;

        let selected = [];

        if (!filterData[fieldModule]) {
            filterData[fieldModule] = {};
        } else {
            selected = filterData[fieldModule][field] || [];
        }

        const paramData = {
            module: this.props.module,
            customData: {
                'listView/selected': selected,
            },
        };

        sAction.openRelatePopup(paramData, (returnData) => {
            this.props.onChange(returnData.id);

            if (returnData.id && Array.isArray(returnData.id)) {
                filterData[fieldModule][field] = returnData.id;
            } else if (returnData.id && returnData.name) {
                filterData[fieldModule][field] = [returnData.id];
            }

            this.setState({selectedCount: filterData[fieldModule][field].length});

            sAction.setStorage('fieldRelateSelected', filterData);
            sAction.popupHide();
        });
    }

    /**
     *
     */
    filterClear() {
        const filterData = sAction.getStorage('fieldRelateSelected') || {};
        const fieldModule = this.props.fieldModule;
        const field = this.props.field;

        if (!filterData[fieldModule]) {
            filterData[fieldModule] = {};
        } else {
            delete filterData[fieldModule][field];
        }

        sAction.setStorage('fieldRelateSelected', filterData);
        this.setState({selectedCount: 0});

        this.props.onChange('');
    }

    render() {
        return (
            <div
                className={'relatedFilterContainer'}
            >
                <div className={'label'}>{this.props.label}</div>
                <div className={'select'} onClick={() => this.openRelateListView()}>
                    <div>
                        {!this.state.selectedCount ? (
                            sAction.translate('LBL_SELECT', '') + '...'
                        ) : (
                            this.state.selectedCount + ' - ' +
                            sAction.translate('LBL_LISTVIEW_SELECTED_OBJECTS').replace(':', '').toLowerCase()
                        )}
                    </div>
                </div>
                <div className="iconset">
                    {!!this.state.selectedCount && (
                        <div className={'iconfa-trash'} onClick={() => this.filterClear()}></div>
                    )}
                    <div className={'icon-search'} onClick={() => this.openRelateListView()}></div>
                </div>
            </div>
        );
    }
}

RelateField.propTypes = {
    module: propTypes.any,
    label: propTypes.any,
    onChange: propTypes.any,
    fieldModule: propTypes.any,
    fieldRel: propTypes.any,
    field: propTypes.any,
    prefix: propTypes.any,
    def: propTypes.any,
};
