import detailDefault from '../../detailDefault';

export default class detailacm_technicians extends detailDefault {
    update(sAction, data) {
        if (data.field === 'acm_technicians_usersusers_ida') {
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/name/value', data.value.name);
            sAction.dsAdd('set', data.prefix + '/changes/fields/name', data.value.name);
            sAction.dsProcess();
        }
    }
}
