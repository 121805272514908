/**
 *
 * @param message
 * @param callback
 * @param callbackCancel
 * @param importance
 */
export default function confirm(message, callback, callbackCancel = () => this.popupHide(), importance = 'low') {
    const data = {
        // Potvrzení
        header: this.translate('LBL_CONFIRM'),
        buttons: [
            // Zrušit
            // Id is for automated tests
            {label: this.translate('LBL_CANCEL', 'Home'), callback: callbackCancel, id: 'confirmPopupCancel'},

            // Pokračovat
            // Id is for automated tests
            {label: this.translate('LBL_CONTINUE', 'Home'), callback: callback, id: 'confirmPopupContinue'},
        ],
    };

    switch (importance) {
        case 'high':
            data.color = 'red';
            break;
    }

    this.popup(message, data, 1500);
}
