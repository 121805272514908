import React, {PureComponent} from 'react';
import TickBox from '../../formElements/TickBox';
import RadioGroup from '../../formElements/RadioGroup';
import Relate from '../../formElements/Relate';
import Button from '../../formElements/Button';
import PropTypes from 'prop-types';
import sAction from 'sAction';
import DetailTabContent from 'ROOT/src/components/DetailViewNew/DetailCard/ClassicView/DetailTabContent';

export default class LeadConvertModule extends PureComponent {
    /**
     * @param {string} prefix
     * @param {*} value
     */
    handleCheck(prefix, value) {
        sAction.dataSet(prefix+'/customData/checked', value);
    }

    /**
     * @param {string} prefix
     * @param {*} item
     */
    handleSelect(prefix, item) {
        if (item.id) {
            sAction.dsClear();
            sAction.dsAdd('set', prefix+'/customData/related_id', item.id);
            sAction.dsAdd('set', prefix+'/customData/related_name', item.name);
            sAction.dsProcess();
        }
    }

    /**
     * @param {Event} e
     * @param {object} data
     */
    show(e, data) {
        e.stopPropagation();
        sAction.rightPanelDetail(data.module, data.record);
    }

    /**
     * @param {string} prefix
     * @param {*} item
     */
    selectCandidate(prefix, item) {
        sAction.dsClear();
        sAction.dsAdd('set', prefix+'/customData/create', false);
        sAction.dsAdd('set', prefix+'/customData/related_id', item.record);
        sAction.dsAdd('set', prefix+'/customData/related_name', item.name);
        sAction.dsProcess();
    }

    /**
     * @param {string} prefix
     * @param {Event} e
     */
    handleRadioVal(prefix, e) {
        sAction.dataSet(prefix + '/customData/create', e.target.value === 'create');
    }

    componentDidMount() {
        const data = this.props.data;
        // sAction.fillDetailPredefinedFields(this.props.way, data.id);

        // prepis hodnoty stavu na default pri Prevádení Zájemce, protože se jinak pošle stav zájemce
        sAction.dataSet(this.props.prefix + '/fields/status/value', sAction.dataGet(this.props.prefix + '/fields/status/def/default'));

        sAction.custom('load', {
            prefix: this.props.prefix,
            module: data.module,
        });
    }

    /**
     * @param {string} prefix
     * @param {string} value
     */
    handleCopyActivities(prefix, value) {
        const way = (this.props.prefix.split('detailViews'))[0] + 'detailViews';
        const modules = sAction.dataGet(way);
        sAction.dsClear();
        sAction.dsAdd('set', prefix + '/customData/activities', value.target.value);
        modules.forEach((value, index) => {
            if (index !== +this.props.index) {
                sAction.dsAdd('set', way + '/' + index + '/customData/activities', 'ignore');
            }
        });
        sAction.dsProcess();
    }

    render() {
        const prefix = this.props.prefix;
        const data = this.props.data;
        const index = this.props.index;

        const customData = data.customData;
        const module = data.module;
        const newRecordAccess = sAction.hasAccess(data.module, 'newRecord');
        const editRecordAccess = sAction.hasAccess(data.module, 'edit');
        const preKey = Date.now();

        let customClass = '';
        if (customData.customClass !== undefined) {
            customClass = ' '+customData.customClass;
        }

        let reqPom = null;

        let dataRel = {};
        if (customData.related_id) {
            dataRel = {value: customData.related_name, id_value: customData.related_id};
        }

        let tick = null;
        let radio = null;
        let headerStr = sAction.translate('LBL_NEW_FORM_TITLE_CL', module);
        let copyActivitiesButton = null;

        if (!customData.required) {
            tick = <TickBox
                className="blueTick"
                checked={customData.checked}
                onChange={(value) => this.handleCheck(prefix, value)} />;
        }

        if (customData.checked) {
            if (customData.allowCopyActivities) {
                copyActivitiesButton = (
                    <>
                        <div className="lcButtonContainer">
                            <RadioGroup
                                autoFocus={true}
                                // onKeyDown={e => this.onKeyDown(e)}
                                key={'activities_' + customData.activities}
                                defaultValue={customData.activities}
                                options={{
                                    'copy': sAction.translate('LBL_COPY_ACTIVITIES'),
                                    'move': sAction.translate('LBL_MOVE_ACTIVITIES'),
                                    'ignore': sAction.translate('LBL_IGNORE_ACTIVITIES'),
                                }}
                                onChange={(e) => this.handleCopyActivities(prefix, e)}
                            />
                        </div>
                        <div className='viewPanelButtonsDelimiter lcButtonDelimiter'></div>
                    </>
                );
            }

            if (customData.boundable) {
                reqPom = <span className="required">*</span>;
                headerStr = sAction.translate('LBL_MODULE_NAME_SINGULAR', module);
                let radioVal = 'select';
                if (customData.create && newRecordAccess) {
                    radioVal = 'create';
                }
                const boundOptions = {};
                if (newRecordAccess) {
                    boundOptions.create = sAction.translate('LBL_NEW_FORM_TITLE_CL', module);
                }
                if (editRecordAccess) {
                    boundOptions.select = sAction.translate('LBL_SELECT_RECORD', module);
                }
                radio = (
                    <>
                        <div className="lcButtonContainer">
                            <RadioGroup
                                autoFocus={true}
                                // onKeyDown={e => this.onKeyDown(e)}
                                key={module+'_'+radioVal}
                                defaultValue={radioVal}
                                options={boundOptions}
                                onChange={(e) => this.handleRadioVal(prefix, e)}
                            />
                        </div>
                    </>
                );
            }
        }

        let candComp = null;

        if (customData.candidates.size > 0) {
            const candidates = [];
            customData.candidates.forEach((cand) => {
                cand.module = data.module;
                let classCand = '';
                if (customData.related_id === cand.record) {
                    classCand = ' selectedCand';
                }
                candidates.push(<div key={cand.record} className={'leadConvertNewRecord'+classCand}>
                    <div onClick={(e) => this.show(e, cand)} className="icon-eye detailViewFieldIconEye" />
                    <a target='_blank' key={cand.record} href={'/#detail/'+data.module+'/'+cand.record} rel="noreferrer">{cand.name}</a>
                    <Button title={sAction.translate('LBL_SELECT_CANDIDATE', 'Leads')} className="hoverBlue" onClick={() => {
                        this.selectCandidate(prefix, cand);
                    }}><div className="icon-arrowUp" /></Button>
                </div>);
            });
            candComp = <div className="leadConvertCandContainer">
                <div className="lCConvertCandLblCol">{sAction.translate('LBL_FOUND_CANDIDATES', 'Leads')}:</div>
                <div className="leadConvertCandidates">
                    {candidates}
                </div>
            </div>;
        }

        let renderComp = null;

        if (customData.checked) {
            if (customData.create && newRecordAccess) {
                const renderTabs = [];

                data.tabs.map((tab, tabIndex) => {
                    tab.panels.map((panel, panelIndex) => {
                        tab = tab.setIn(['panels', panelIndex, 'name'], (panel.get('name') || tab.name));

                        if (!(panelIndex || tabIndex)) {
                            tab = tab.setIn(['panels', panelIndex, 'name'], '');
                        }
                    });

                    renderTabs.push(
                        <DetailTabContent
                            key={index}
                            tab={tab}
                            prefix={prefix}
                            module={data.module}
                            fields={data.fields}
                            acl={data.acl}
                            readonly={data.readonly}
                            attemptedSave={data.attemptedSave}
                            tabWay={`${prefix}/tabs/0`}
                            id={data.id}
                        />,
                    );
                });

                renderComp = (
                    <div className='detailView'>
                        {renderTabs}
                    </div>
                );
            } else {
                renderComp = <div className="detailView lcSelectDetailView">
                    <div className={'lcSelectContainer LeadConvertRelInput'+customClass}>
                        <Relate
                            newRecord={false}
                            data={dataRel}
                            module={module}
                            buttons={[]}
                            callback={(item) => this.handleSelect(prefix, item)}
                            key={preKey+module}
                        />
                    </div>
                </div>;
            }
        }

        return (
            <div className={'LeadConvertModule'+customClass} id={this.props.data.module}>
                <div className="detailCard">
                    <div className="header">
                        {tick}
                        <div className="title">{headerStr}</div>
                        {reqPom}
                    </div>
                    {customData.checked && (
                        <div className='leadConvertHeader'>
                            <div className='lcButtonHeader'>
                                {copyActivitiesButton}
                                {radio}
                            </div>
                            {candComp}
                        </div>
                    )}
                    {renderComp}
                </div>
            </div>
        );
    }
}

LeadConvertModule.propTypes = {
    prefix: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    customData: PropTypes.object,
    data: PropTypes.object,
};
