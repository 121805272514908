/**
 * Get available languages
 * @return {Array} Available languages
 */
export default function getAvailableLanguage() {
    const availLang = this.dataGet('conf/availableLanguages');
    if (availLang) {
        return availLang;
    }

    return [];
}
